/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'profile': {
    width: 19,
    height: 20,
    viewBox: '0 0 19 20',
    data: '<path pid="0" d="M13.718 6.201c0 2.142-1.772 3.915-4.002 3.915-2.23 0-4.002-1.773-4.002-3.915 0-2.142 1.772-3.914 4.002-3.914 2.23 0 4.002 1.772 4.002 3.914zm-9.44 0c0 2.093 1.22 3.903 2.995 4.783C3.619 11.899.85 14.826.85 18.432a.718.718 0 001.437 0c0-3.39 3.223-6.31 7.429-6.31 4.205 0 7.429 2.92 7.429 6.31a.718.718 0 001.437 0c0-3.606-2.77-6.533-6.423-7.448 1.775-.88 2.995-2.69 2.995-4.783 0-2.958-2.437-5.351-5.438-5.351S4.277 3.243 4.277 6.201z" _fill="#898B9B" _stroke="#898B9B" stroke-width=".3"/>'
  }
})
